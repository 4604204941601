<script>
import ApiService from "@/services/api.service";
export default {
  data() {
    return {
      preference: {},
      product: {},
      organization_product: {},
      form: {
        status: null,
        allow_public: null,
        allow_payment: null,
        moveable_property_count: 0,
        immoveable_property_count: 0,
        receiver_count: 0,
        caretaker_count: 0,
        fixed_saham_type: 0,
        corporate_saham_account_type: null,
      },
    };
  },
  mounted() {
    console.log(this.organizationId);
    this.getPreference();
    this.getProducts();
    this.getOrganizationProduct();
    console.log("ProductId", this.productId);
  },
  methods: {
    getPreference() {
      ApiService.get(`organization/${this.organizationId}/preference`).then(
        (response) => {
          this.preference = response.data.preference ?? {};
        }
      );
    },
    getProducts() {
      ApiService.get(`product/${this.productId}`).then((response) => {
        console.log("Product", response);
        this.product = response.data.product;
      });
    },
    getOrganizationProduct() {
      ApiService.get(
        `organization-product?organization_id=${this.organizationId}&product_id=${this.productId}`
      ).then((response) => {
        console.log("Organization Product", response);
        this.organization_product = response.data.organization_product ?? {};
        this.form = this.organization_product;
      });
    },
    submitOrganizationProduct() {
      this.form.organization_id = this.organizationId;
      this.form.product_id = this.productId;
      ApiService.post(`organization-product`, this.form)
        .then((response) => {
          this.$swal
            .fire({
              icon: "success",
              html: this.$t("update-success"),
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonText: this.$t("close"),
            })
            .then((response) => {
              this.$router.push({
                name: "organization.product.index",
                params: {
                  organization_id: this.organizationId,
                },
              });
            });
          this.getPreference();
          this.getProducts();
          this.getOrganizationProduct();
        })
        .catch((error) => {
          this.isSubmit = false;
        });
    },
    back() {
      this.$router.push({
        name: "organization.product.index",
        params: {
          organization_id: this.organizationId,
        },
      });
    },
  },
  computed: {
    organizationId() {
      return this.$route.params.organization_id;
    },
    organizationType() {
      return this.organization.type;
    },
    productId() {
      return this.$route.params.product_id;
    },
  },
  props: {
    organization: {
      type: Object,
    },
  },
};
</script>
    
<template>
  <div v-if="preference.allow_wasiat == 1">
    <div class="row">
      <div class="col-md-12">
        <h5>{{ this.product.name }}</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 mt-3">
        <p>
          1. {{ $t("preference.activate") }}
          <label class="switch">
            <input type="checkbox" value="1" v-model="form.status" />
            <span class="slider round"></span>
          </label>
        </p>
      </div>
      <div class="col-md-4 mt-3">
        <p>
          2. {{ $t("preference.allow-public") }}
          <label class="switch">
            <input type="checkbox" value="1" v-model="form.allow_public" />
            <span class="slider round"></span>
          </label>
        </p>
      </div>
    </div>
    <hr />
    <div class="d-flex justify-content-between">
      <button type="button" @click="back" class="btn btn-primary">
        {{ $t("cancel") }}
      </button>
      <button
        type="button"
        @click="submitOrganizationProduct"
        class="btn btn-primary"
      >
        <i class="mdi mdi-file-document"></i>{{ $t("save") }}
      </button>
    </div>
  </div>
</template>
    