var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.preference.allow_wasiat == 1 ? _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('h5', [_vm._v(_vm._s(this.product.name))])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4 mt-3"
  }, [_c('p', [_vm._v(" 1. " + _vm._s(_vm.$t("preference.activate")) + " "), _c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.status,
      expression: "form.status"
    }],
    attrs: {
      "type": "checkbox",
      "value": "1"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.status) ? _vm._i(_vm.form.status, "1") > -1 : _vm.form.status
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.form.status,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "1",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "status", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "status", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "status", $$c);
        }
      }
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])]), _c('div', {
    staticClass: "col-md-4 mt-3"
  }, [_c('p', [_vm._v(" 2. " + _vm._s(_vm.$t("preference.allow-public")) + " "), _c('label', {
    staticClass: "switch"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.allow_public,
      expression: "form.allow_public"
    }],
    attrs: {
      "type": "checkbox",
      "value": "1"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.allow_public) ? _vm._i(_vm.form.allow_public, "1") > -1 : _vm.form.allow_public
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.form.allow_public,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "1",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "allow_public", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "allow_public", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "allow_public", $$c);
        }
      }
    }
  }), _c('span', {
    staticClass: "slider round"
  })])])])]), _c('hr'), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.back
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.submitOrganizationProduct
    }
  }, [_c('i', {
    staticClass: "mdi mdi-file-document"
  }), _vm._v(_vm._s(_vm.$t("save")) + " ")])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }